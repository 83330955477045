import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import {
  Button,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Checkbox,
  DatePicker,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import "../css/UserForm.css";
import { SaveOutlined } from '@ant-design/icons';


function UserForm() {
  const [form] = Form.useForm();
  const [formSchema, setFormSchema] = useState([]);
  const [formTitle, setFormTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submissionMessage, setSubmissionMessage] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [lastPauseTime, setLastPauseTime] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [accumulatedDuration, setAccumulatedDuration] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [existingData, setExistingData] = useState(null);
  const [welcomeBackMessage, setWelcomeBackMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  const getIdFromUrl = () => {
    const params = new URLSearchParams(location.search);
    return params.get("id");
  };

  const id = getIdFromUrl();

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const calculateDuration = () => {
    if (!lastPauseTime) return 0;
    const now = new Date().getTime();
    const currentSessionDuration = Math.floor((now - lastPauseTime) / 1000);
    return currentSessionDuration;
  };

  const sendDurationData = async (status, duration, formObject) => {
    try {
      if (!id) return;
      const payload = {
        id,
        status,
        duration,
        endTime: new Date().toISOString(),
      };
      // Add formResponse only if formData exists in formObject
      if (formObject?.formData) {
        payload.formResponse = formObject.formData;
      }
      console.log("payload", payload);
      await axios.post(
        "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-response/survey-duration",
        payload
      );

      if (status === "COMPLETED") {
        setIsSubmitted(true);
        console.log("status:", isSubmitted);
      }

      if (duration && duration > 0) {
        localStorage.setItem(`form_duration_${id}`, duration.toString());
      }
    } catch (error) {
      console.error("Failed to send duration data:", error);
    }
  };

  const handleSave = async () => {
    try {
      const currentFormValues = form.getFieldsValue();
      const currentSessionDuration = calculateDuration();
      const totalDuration = accumulatedDuration + currentSessionDuration;

      // Create the form object with current values
      const formObject = {
        id: id,
        formData: currentFormValues,
        status: "pending"
      };

      console.log("formObject", formObject);

      // Save the partial response
      const saveResponse = await axios.post(
        "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-response/survey-response-to-dynamo",
        formObject
      );

      // Update duration with PARTIAL status
      await sendDurationData("PARTIAL", totalDuration, formObject);
      setAccumulatedDuration(totalDuration);

      if (saveResponse.status === 200) {
        toast.success("Progress saved successfully!");
      }
    } catch (error) {
      console.error("Save progress error:", error);
      toast.error("Failed to save progress. Please try again.");
    }
  };


  const initializeForm = async () => {
    try {
      setIsLoading(true);

      if (!id) {
        setErrorMessage("Form ID is missing");
        setIsLoading(false);
        return;
      }

      let existingDuration = 0;
      let storedDuration = 0;

      try {
        const durationResponse = await axios.get(
          `https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-response/check-form-status?id=${id}`
        );

        console.log("Duration response:", durationResponse);

        if (durationResponse?.data) {
          setExistingData(durationResponse.data);

          if (durationResponse.data.status === 'COMPLETED') {
            setIsSubmitted(true);
            setSubmissionMessage("This form has already been submitted.");
            setIsLoading(false);
            return;
          }

          if (durationResponse.data.duration !== null && durationResponse.data.duration !== undefined) {
            existingDuration = parseInt(durationResponse.data.duration) || 0;
            storedDuration = parseInt(localStorage.getItem(`form_duration_${id}`)) || 0;

            const finalDuration = Math.max(existingDuration, storedDuration);

            if (finalDuration > 0) {
              console.log("Setting accumulated duration to:", finalDuration);
              setAccumulatedDuration(finalDuration);
              localStorage.setItem(`form_duration_${id}`, finalDuration.toString());

              setWelcomeBackMessage("Welcome back! Your progress has been saved.");
              setTimeout(() => {
                setWelcomeBackMessage("");
              }, 5000);
            }
          }

          if (durationResponse.data.formResponse) {
            const data= JSON.parse(durationResponse.data.formResponse);
            console.log("Setting saved form data:", durationResponse.data.formResponse);
            form.setFieldsValue(data);
          }

        }
      } catch (error) {
        console.error("Duration check error:", error);
      }

      const formResponse = await axios.get(
        `https://qyow74zw35.execute-api.ap-south-1.amazonaws.com/dev/survey-form-user?id=${id}`
      );

      if (formResponse?.data) {
        if (formResponse.data.form_schema || formResponse.data.title) {
          console.log(formResponse.data.title, "form title");
          console.log(formResponse, "form response");
          setFormSchema(formResponse.data.form_schema || []);
          setFormTitle(formResponse.data.title || "");
          setErrorMessage("");

          const currentTime = new Date().getTime();
          setStartTime(currentTime);
          setLastPauseTime(currentTime);
          setIsActive(true);
          
          // if (durationResponse?.data?.formData) {
          //   // Populate the form with saved data
          //   form.setFieldsValue(durationResponse.data.formData);
          // }
        } else {
          setErrorMessage("This form has already been submitted.");
        }
      } else {
        setErrorMessage("Invalid form data received");
      }

      setIsLoading(false);
    } catch (error) {
      console.error('Form fetch error:', error);
      if (error.response?.status === 404) {
        setErrorMessage("Form not found");
      } else {
        setErrorMessage("An error occurred while loading the form");
      }
      setIsLoading(false);
    }
  };

  const onFinish = async (values) => {
    try {
      if (!id) return;
      const hasFileUpload = formSchema.some((field) => field.field_type === "file");
      const fileLabel = formSchema.filter((form) => form.field_type === "file");
      let file_names = [];

      try {
        await Promise.all(
          fileLabel.map(async (file) => {
            let label = file.label;
            if (hasFileUpload && values[label] && values[label].length > 0) {
              const file_name = values[label][0].name;
              const fileName = `${id}_${file_name}`;
              console.log("fileName:", fileName);
              file_names.push(fileName);
              const response = await axios.get(
                `https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-response/get-pre-signed-url?id=${id}&fileName=${file_name}`
              );
              const uploadUrl = response.data.uploadUrl;
              await axios.put(uploadUrl, values[label][0].originFileObj, {
                headers: {
                  "Content-Type": values[label][0].type,
                },
              });
            }
          })
        );
      } catch (error) {
        toast.error("Failed to upload file");
        return;
      }

      // Dynamically filter out file upload fields from the form data
      const filteredFormData = Object.keys(values).reduce((acc, key) => {
        const field = formSchema.find((f) => f.label === key);
        if (field && field.field_type !== "file") {
          acc[key] = values[key];
        }
        return acc;
      }, {});

      console.log("fileNames", file_names);
      const formObject = {
        id: id,
        formData: {
          ...filteredFormData,
          file_names,
        },
        status: "submitted"
      };

      console.log("form Object ", formObject);

      const saveResponse = await axios.post(
        "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-response/survey-response-to-dynamo",
        formObject
      );

      const currentSessionDuration = calculateDuration();
      const totalDuration = accumulatedDuration + currentSessionDuration;

      if (saveResponse.status === 200) {
        setIsSubmitted(true);
        console.log("send duration when successs" + totalDuration);
        await sendDurationData("COMPLETED", totalDuration, formObject);
        setSubmissionMessage(
          "Thank you for submitting the form. Your response has been recorded."
        );
        toast.success("Form submitted successfully!");
        localStorage.removeItem(`form_duration_${id}`);
      }
    } catch (error) {
      console.error("Form submission error:", error);
      toast.error("Failed to submit form. Please try again.");
    }
  };

  useEffect(() => {
    if (id) {
      initializeForm();
    } else {
      setErrorMessage("Form ID is missing");
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (isSubmitted || !id) return;

    const handleVisibilityChange = () => {
      if (document.hidden) {
        setIsActive(false);
        const currentSessionDuration = calculateDuration();
        const totalDuration = accumulatedDuration + currentSessionDuration;
        console.log("Saving duration on hide:", totalDuration);

        // Get current form values
        const currentFormValues = form.getFieldsValue();

        // Create form object with current values
        const formObject = {
          id: id,
          formData: currentFormValues
        };
        if (totalDuration > 0) {
          sendDurationData("PARTIAL", totalDuration, formObject);
          setAccumulatedDuration(totalDuration);
          localStorage.setItem(`form_duration_${id}`, totalDuration.toString());
        }
      } else {
        setIsActive(true);
        setLastPauseTime(new Date().getTime());
      }
    };

    const handleBeforeUnload = (event) => {
      if (!isSubmitted) {
        event.preventDefault();
        const currentSessionDuration = calculateDuration();
        const totalDuration = accumulatedDuration + currentSessionDuration;

        if (totalDuration > 0) {
          console.log("Saving duration on unload:", totalDuration);
          const payload = {
            id: id,
            status: "PARTIAL",
            duration: totalDuration,
            endTime: new Date().toISOString(),
          };

          const blob = new Blob([JSON.stringify(payload)], {
            type: "application/json",
          });
          navigator.sendBeacon(
            "https://puebem38qc.execute-api.ap-south-1.amazonaws.com/dev/survey-response/survey-duration",
            blob
          );
          localStorage.setItem(`form_duration_${id}`, totalDuration.toString());
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handleBeforeUnload);

      if (!isSubmitted) {
        const currentSessionDuration = calculateDuration();
        const totalDuration = accumulatedDuration + currentSessionDuration;
        // Get current form values before cleanup
        const currentFormValues = form.getFieldsValue();
        const formObject = {
          id: id,
          formData: currentFormValues
        };
        if (totalDuration > 0) {
          console.log("Saving duration on cleanup:", totalDuration, formObject);
          sendDurationData("PARTIAL", totalDuration, formObject);
          localStorage.setItem(`form_duration_${id}`, totalDuration.toString());
        }
      }
    };
  }, [ lastPauseTime, isActive, accumulatedDuration, id]);

  useEffect(() => {
    return () => {
      if (!isSubmitted && id) {
        const currentSessionDuration = calculateDuration();
        const totalDuration = accumulatedDuration + currentSessionDuration;
        if (totalDuration > 0) {
          localStorage.setItem(`form_duration_${id}`, totalDuration.toString());
        }
      }
    };
  }, [isSubmitted, accumulatedDuration, id]);

  const renderFormField = (field) => {
    switch (field.field_type) {
      case "text":
        return (
          <Form.Item
            label={field.label}
            name={field.label}
          >
            <Input placeholder={field.placeholder} />
          </Form.Item>
        );
      case "email":
        return (
          <Form.Item
            label={field.label}
            name={field.label}
          >
            <Input type="email" placeholder={field.placeholder} />
          </Form.Item>
        );

      case "number":
        return (
          <Form.Item
            label={field.label}
            name={field.label}
          >
            <InputNumber placeholder={field.placeholder} />
          </Form.Item>
        );

      case "password":
        return (
          <Form.Item
            label={field.label}
            name={field.label}
          >
            <Input.Password placeholder={field.placeholder} />
          </Form.Item>
        );

      case "checkbox":
        return (
          <Form.Item label={field.label} name={field.label}>

            <Checkbox.Group>
              {field.options.map((option, index) => (
                <Checkbox value={option}>{option}</Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item
            label={field.label}
            name={field.label}
          >
            <Radio.Group>
              {field.options.map((option, index) => (
                <Radio key={index} value={option}>
                  {option}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        );

      case "dropdown":
        return (
          <Form.Item
            label={field.label}
            name={field.label}
          >
            <Select placeholder={field.placeholder}>
              {field.options.map((option, index) => (
                <Select.Option key={index} value={option}>
                  {option}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        );

      case "date":
        return (
          <Form.Item
            label={field.label}
            name={field.label}
          >
            <DatePicker />
          </Form.Item>
        );

      case "textarea":
        return (
          <Form.Item
            label={field.label}
            name={field.label}
          >
            <TextArea placeholder={field.placeholder} />
          </Form.Item>
        );

      case "file":
        return (
          <Form.Item
            label={field.label}
            name={field.label}
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <Upload action="/upload.do" listType="picture-card" maxCount={1}>
              <button
                style={{
                  border: 0,
                  background: "none",
                }}
                type="button"
              >
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 5,
                  }}
                >
                  Upload
                </div>
              </button>
            </Upload>
          </Form.Item>
        );
      case "button":
        return (
          <Form.Item
            wrapperCol={{
              offset: 6,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              {field.placeholder}
            </Button>
          </Form.Item>
        );
      default:
        return <div>Unknown Form Item</div>;
    }
  };

  return (
    <div className="user-body">
      {isLoading ? (
        <div className="loading-message">Loading form...</div>
      ) : (
        <>
          {errorMessage && <div className="error-message">{errorMessage}</div>}
          {submissionMessage && (
            <div className="success-message">{submissionMessage}</div>
          )}
          {welcomeBackMessage && (
            <div className="welcome-back-message">{welcomeBackMessage}</div>
          )}
          {!errorMessage && !submissionMessage && (
            <>
              <div style={{display:"flex",justifyContent:"space-between"}}><h2 className="form-title" style={{marginLeft:"50%"}}>{formTitle}</h2>
              <Button
                    type="default"
                    onClick={handleSave}
                    icon={<SaveOutlined style={{ color: 'white' }} />}
                    style={{
                      marginTop:"5vh",
                      backgroundColor: '#1890ff',
                      borderColor: '#1890ff',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                      color: 'white'
                    }}
                  >
                    Save Progress
                  </Button></div>
              <Form form={form} layout="vertical" style={{ width: "100%", justifySelf: "center" }}
                onFinish={onFinish}>
                <div style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '20px',
                  marginBottom: '20px',
                  bottom: '50px',
                  right: '100px',
                }}>

                  
                </div>
                {formSchema.map((field, index) => (
                  <div key={index}>{renderFormField(field)}</div>
                ))}
                <div className="submit-button-container">
                </div>
                <Form.Item>
                </Form.Item>
              </Form>
            </>
          )}
        </>
      )}
      <ToastContainer position="top-center" />
    </div>
  );
}

export default UserForm;
